import React from "react";
import { graphql } from "gatsby";
import Previewable from "../components/Previewable";
import SEO from "../components/SEO";
import { Header } from "../components/Acf/Header";
import "./post.scss";
import { decodeEntities, stripHtmlTags } from "../utils/helpers";
import { AcfComponent } from "./componentLoader";
import { ImageAndContentBlock } from "../components/Acf/ImageAndContentBlock";

const ProjectPost = (props) => {
  const { data, location } = props;
  const { wordpressPost: post, wordpressWpSettings, siteSettings } = data;
  const { wordpressUrl } = wordpressWpSettings;
  const { options = null } = siteSettings;
  if (!post) return null;
  const {
    title,
    content,
    excerpt,
    featured_media,
    yoast,
    categories,
    date,
    author,
    acf,
  } = post;

  const { layout } = acf || { layout: null };

  const bannerImage =
    layout.find((field) => field.__typename.includes("postInformation"))
      ?.bannerImage || featured_media;

  return (
    <>
      <SEO
        title={`${yoast.metaTitle || decodeEntities(title)} | ${decodeEntities(
          wordpressWpSettings.title
        )}`}
        desc={excerpt ? stripHtmlTags(excerpt) : null}
        yoast={yoast}
        banner={bannerImage?.source_url}
        location={location}
      />
      <Header header={title} image={bannerImage} compact />
      {layout &&
        layout.map((item, index) => {
          if (!item.__typename) return null;
          const layoutComponentName = item.__typename.replace(
            "WordPressAcf_",
            ""
          );
          return (
            <AcfComponent
              key={index}
              index={index}
              componentName={layoutComponentName}
              item={item}
              location={location}
            />
          );
        })}
    </>
  );
};

export default Previewable(ProjectPost, "projects");

export const pageQuery = graphql`
  fragment ProjectFields on wordpress__wp_projects {
    id
    slug
    content
    date(formatString: "MMMM Qo gggg")
    title
  }
  query ProjectPostByID($id: String!) {
    wordpressWpSettings {
      title
      wordpressUrl
      siteUrl
    }
    siteSettings: wordpressAcfOptions {
      options {
        customCss
      }
    }
    categories: allWordpressWpProjectCategory(filter: { count: { gt: 0 } }) {
      edges {
        node {
          name
          slug
          path
          count
          wordpress_id
        }
      }
    }
    wordpressPost: wordpressWpProjects(id: { eq: $id }) {
      id
      title
      slug
      content
      excerpt
      wordpress_id
      date(formatString: "MMMM Qo gggg")
      featured_media {
        source_url
        localFile {
          childImageSharp {
            fluid(maxWidth: 1100, quality: 80) {
              ...GatsbyImageSharpFluid_noBase64
            }
          }
        }
      }
      acf {
        layout: layout_projects {
          __typename
          ...PostInformationQuery
          ...HeaderQuery
          ...BlogFeedQuery
          ...StaffProfilesQuery
          ...FullWidthImageContainedQuery
          ...FullWidthImageQuery
          ...ImageAndContentBlockQuery
          ...ContentAndIconListQuery
          ...TwoCardBlockQuery
          ...TwoColumnQuery
          ...ProjectOverviewQuery
          ...FullWidthCarouselQuery
          ...AccordionQuery
          ...TestimonialBlockQuery
        }
      }
      yoast {
        metaTitle: title
        metaDescription: metadesc
        opengraph_image {
          source_url
        }
        twitter_image {
          source_url
        }
      }
    }
  }
`;
